/* mini logo */
  /* .m-cls-1, .cls-2 {
    fill: #fff;
  } */

  /* .m-cls-3 {
    fill: #231f20;
    stroke: #231f20;
    stroke-miterlimit: 10;
    stroke-width: 4px;
  } */

  .m-cls-4 {
    fill: #21409a;
  }

  .m-cls-2 {
    font-family:'Public Sans,sans-serif';
    font-size: 9.55px;
    font-weight: 700;
  }